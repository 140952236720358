import React from 'react';
import { useIntl } from 'react-intl';

import { PriceType } from '@/redux/selectors/listing/page';
import { formatCurrency } from '@/utility/currency';

import { PriceSize } from '../Price';
import css from './PriceCurrent.module.css';

type ICurrentPriceElement = React.HTMLAttributes<HTMLElement>;

interface IProps {
  currency: string;
  currentPrice: number;
  period: PriceType;
  size?: PriceSize;
  testId?: string;
  isCampsite?: boolean;
}

const priceTemplate = (value: string): React.ReactElement => (
  <span aria-hidden className={css.price}>
    {value}
  </span>
);

const periodTemplate = (period: string): React.ReactElement => (
  <span aria-hidden className={css.period}>
    {period}
  </span>
);

const PriceCurrent: React.FC<IProps & ICurrentPriceElement> = ({
  size = PriceSize.medium,
  currency,
  currentPrice,
  period,
  testId = 'price-current',
  isCampsite = false,
}) => {
  const intl = useIntl();
  const formattedPrice = formatCurrency({
    currency,
    digits: 0,
    maxDigits: 0,
    priceInCents: currentPrice,
  });

  const screenReaderMessage = intl.formatMessage(
    {
      description: '[Price]: screen reader message for current price per period (day/night)',
      defaultMessage: '{currentPrice} per {period}',
      id: 'RgOVlk',
    },
    {
      currentPrice: formattedPrice,
      period: period,
    },
  );

  return (
    <p
      className={css.container}
      aria-label={screenReaderMessage}
      data-testid={testId}
      data-size={size}>
      {intl.formatMessage(
        {
          description: '[Price]: current price formatting for a listing',
          defaultMessage:
            '<priceTemplate>{value}</priceTemplate><periodTemplate>/{period, select, day {day} night {night} other {}}</periodTemplate>',
          id: 'eJ7KW9',
        },
        {
          priceTemplate: priceTemplate,
          periodTemplate: isCampsite ? priceTemplate : periodTemplate,
          period: period?.toLowerCase(),
          value: formattedPrice,
        },
      )}
    </p>
  );
};

export default PriceCurrent;

import { Card, ETextStyleVariant, SkeletonLine, Text } from '@outdoorsyco/bonfire';
import React from 'react';

import { isCampgroundTile, ISearchResultTile } from '@/utility/mapSearchResultToTile';

import { CampgroundPrice } from './CampgroundPrice';
import { PriceDetails } from './PriceDetails';
import { RentalInfo } from './RentalInfo';
import { RentalPrice } from './RentalPrice';
import { RentalRating } from './RentalRating';

type TListingTileContentProps = {
  rentalTile: ISearchResultTile;
  customTitle?: string;
};

export const ListingTileContent = ({ rentalTile, customTitle }: TListingTileContentProps) => {
  const isCampground = isCampgroundTile(rentalTile);

  const priceDetails =
    rentalTile.price &&
    !isCampground &&
    (rentalTile.isCampsite ? (
      <RentalPrice currency={rentalTile.currency} {...rentalTile.price} isCampsite />
    ) : (
      <PriceDetails showDiscount {...rentalTile.price} />
    ));

  return (
    <Card.Content className="w-full py-2">
      <Text component="span" variant={ETextStyleVariant.MediumBold} className="line-clamp-2">
        {(customTitle ? customTitle : rentalTile.title) ??
          (rentalTile.loading && <SkeletonLine className="bg-gray-200" />)}
      </Text>

      <RentalInfo
        isCampground={isCampground}
        campgroundSubtitle={(isCampground && rentalTile.sitesDisplayText) || ''}
        loading={rentalTile.loading}
        isStay={isCampground ? false : rentalTile.isStay}
        title={rentalTile.title}
        {...rentalTile.details}
        hideDetails={!!customTitle}
      />

      <div className="flex items-center justify-between my-1 gap-2">
        {rentalTile.price ? (
          isCampground ? (
            <CampgroundPrice currency={rentalTile.currency} {...rentalTile.price} />
          ) : (
            priceDetails
          )
        ) : (
          rentalTile.loading && (
            <Text component="span" variant={ETextStyleVariant.LargeBold} className="grow">
              <SkeletonLine className="bg-gray-200" />
            </Text>
          )
        )}

        {!!rentalTile.details?.ratingNumber && (
          <RentalRating
            rating={rentalTile.details.ratingNumber}
            quantity={rentalTile.details.ratingQtd}
          />
        )}
      </div>
    </Card.Content>
  );
};
